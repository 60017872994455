import {Entity} from '../core/main.entity';
import {ProductOffer} from './productOffer.entity';
import {RecommendProduct} from './recommendProduct.entity';
import {WokItem} from './wokItem.entity';


export class Product implements Entity {
    id: number;
    dishId: number | string;
    name: number;
    categoryId: number;
    subCategoryIndex: number | null;
    type: string; // 'basic' | 'pizza' | 'drink' | 'pie'
    offerSelectType: string;  // 'select' | 'radio';
    price: number;
    fprice: number;
    oldPrice: number;
    'preview_text': string;
    isFavorite?: boolean;
    isProductOfTheDay: boolean;
    isPopular: boolean;
    isWeekdayDiscount: boolean;
    isStopList: boolean;
    hasSauces: boolean;
    isSauce: boolean;
    imageUrl: string;
    offers: ProductOffer[];
    offerId: number;
    isWok: boolean;
    isPizza: boolean;
    pizzaId: string;
    imageUrlCard: any;
    weight: any;                        // к значению добавить граммы (например, 300 гр.)
    'detail_text': any;
    mainId: number;
    defaultImg: string;
    catName: string;
    quantity = 1;
    category: string;
    offerSize: string;
    labels: string[];

    isHotForEveryDay: boolean;          // раздел "горячее" для категории "Еда на каждый день"
    sideDish: string[];                 // массив с гарнирами (например, ['с брокколи', 'с булгуром']),
    activeSideDish: string;
    recommendItems: RecommendProduct[];
    nutritionalValue: NutritionalValue;
    nutritionalValue100: NutritionalValue;
    description: string;               // описание состава Wok-конструктора
    options: WokItem[];
    selectSize: string;
    size: string;
    sizeType: number;                  // 1 - small | 2 - large (3 - middle)
    availability: boolean; // Доступность товара
    availabilityText: string; // Если есть и availability = false - показывать этот текст вместо кнопок добавления в корзину
    slug: string;

    animateTransition: string; // Флаг, при наличии которого элемент должен появиться в списке с анимацией
    /*
    * @TODO тут мы будем считать цены товаров
    * */

    public getPrice(qty: number, typePrice = 'price') {

        if (!this.offerId) {
            if (this.options) {
                return this.options.reduce((total, item) => total + +item[typePrice], 0) * qty;
            } else {
                return ((typePrice === 'price') ? this.price : this.oldPrice) * qty;
            }

        } else {
            const offer = this.offers.find(o => {
                return o.id === this.offerId;
            });
            return ((typePrice === 'price') ? offer.price : offer.oldPrice) * qty;
        }
    }

    public getOffer() {
        return this.offers;
    }
}

export class NutritionalValue implements Entity {
    proteins: string;
    fats: string;
    carbo: string;
    kkal: string;
}
