import {Entity} from '../core/main.entity';
import {Cities, Gender} from './lists';
import {DeliveryType} from './cart.entity';
import {Address} from './address.entity';
import {AddressSearchAnswer} from './addressSearchAPIAnswer.entity';
import {Branch} from './restaurant.entity';

export class UserProfile implements Entity {
    active: boolean | true;
    id = '0';
    userId = '0';
    name = '';
    birthday: string | null = null;
    email: string | null = null;
    phone: string | null = null;
    gender: Gender;
    avatarUrl: string | null;
    login: string;
    clearLocalStorageAddress?: boolean;

    defaultAddress: DefaultAddress;

    // myAddresses: MyAddress[];
    // orderComments: Comment[];

    // orderHistory: OrderHistoryRecord[];

    enableNotifications: boolean | true;
    enableStatusChangePushNotification: boolean | true;
    enableStatusChangeSmsNotification: boolean | true;
    enableStatusChangeEmailNotification: boolean | true;
    enableNewsPushNotification: boolean | true;
    enableNewsSmsNotification: boolean | true;
    enableNewsEmailNotification: boolean | true;
    enablePromotionsPushNotification: boolean | true;
    enablePromotionsSmsNotification: boolean | true;
    enablePromotionsEmailNotification: boolean | true;

    error: string | null;
    city: Cities;
    referralUrl: string;
}

export class DefaultAddress implements Entity {
    type: DeliveryType = DeliveryType.Courier;
    courier: Address = new Address();
    pickup: Branch = new Branch();
    courierPoint: AddressSearchAnswer = new AddressSearchAnswer();
    useGeolocation?: boolean;
}
