import {Component, OnInit} from '@angular/core';
import {CatalogService} from '../../../models/catalog.service';
import {AppService} from '../../../services/app.service';
import {CartService} from '../../../models/cart.service';

@Component({
    selector: 'app-popup-souses-list',
    templateUrl: './popup-souses-list.component.html',
    styleUrls: ['./popup-souses-list.component.scss']
})
export class PopupSousesListComponent implements OnInit {
    catalogService: CatalogService;
    appService: AppService;
    products: any[] = [];
    text: any = 'text from server';
    textIsOpen = false;
    saucesExist = false;

    constructor(
        catalogService: CatalogService,
        appService: AppService,
        private cartService: CartService
    ) {
        this.catalogService = catalogService;
        this.appService = appService;
    }

    ngOnInit() {
        this.products = this.catalogService.findByIdProperty('isSauce', true);
        this.sousesQuantity();
    }

    close() {
        this.appService.closeModal();
    }

    goToDelivery() {
        this.saucesExist ? this.appService.closeModal({action: 'goToDelivery'}) : this.close();
    }

    sousesQuantity() {
        const products = this.cartService.cart.products;

        // Фильтруем продукты, где `isSauce` равен true
        const sauces = products.filter(product => product.isSauce);

        // Считаем общее количество соусов, учитывая их `qty`
        const totalSaucesQuantity = sauces.reduce((total, product) => total + product.qty, 0);

        this.saucesExist =  totalSaucesQuantity > 0;
    }
}
