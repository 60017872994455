import {EventEmitter, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AlertController, LoadingController} from '@ionic/angular';
import {MainService} from '../core/main.service';
import {AppService} from '../services/app.service';
import {ApiAnswer, Cart, CartProduct, CartPromo, CartUnavailableProducts, OrderStep} from '../entity/cart.entity';
import {plainToClass} from 'class-transformer';
import {CancelHttpService, CancelHttpType} from '../services/cancel-http.service';
import {HelperService} from '../services/helper.service';
import {NavigationService} from '../services/navigation.service';
import {PopupMessageComponent} from '../components/popups/popup-message/popup-message.component';
import {environment} from '../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class CartService extends MainService {

    private cancelHttpService: CancelHttpService;
    cart: Cart = new Cart();
    syncTimerDelay = 5 * 1000;
    syncTimer = null;
    updateQuantity$: EventEmitter<any> = new EventEmitter<any>();
    isPromo: boolean = null; // null - не проверяется валидность промо. true - код валиден, false - код перестал быть валидным
    private activeOffer: string;
    public unavailableProductsToShow: CartUnavailableProducts = null;
    isSpbPayment = false;
    order_id?: string;

    constructor(
        protected http: HttpClient,
        alert: AlertController,
        loader: LoadingController,
        appService: AppService,
        cancelHttpService: CancelHttpService,
        private navigationService: NavigationService
    ) {
        super(http, alert, loader, appService);
        this.cancelHttpService = cancelHttpService;


        if (!environment.production) {
            (this.appService.getWindow() as any).cartService = this;
        }
    }


    newCart() {
        this.stopSyncTimer();

        this.cart = new Cart();
        this.cart.promo.code = '';

        this.cart.data.persons = 1;
        this.addDeviceInfo();

        // this.addUserData();
    }

    addDeviceInfo() {

        if (this.cart?.data) {
            this.cart.data.device = this.appService.isIos() ? 'ios' : 'android';
            this.cart.data.version = this.appService.appVersionNumber;
        }
    }

    cleanProducts(syncTime = null, syncLoading = false) {
        delete this.cart.delivery.courierDate;
        delete this.cart.delivery.courierTime;
        delete this.cart.delivery.pickupDate;
        delete this.cart.delivery.pickupTime;
        this.cart.products.length = 0;
        this.setCartDate();
        this.appService.cleanCdr();

        this.startSyncTimer(syncTime, syncLoading, null, OrderStep.Cart);
    }

    addUserData(user) {

        if (this.cart && this.cart.data) {
            this.cart.data.name = user.name;
            this.cart.data.email = user.email;
            this.cart.data.phone = user.phone;
        }

    }

    setCartDate() {
        if (this.cart) {
            this.cart.date = (new Date()).getTime();
        }
    }

    addToCart(
        product,
        qty = +1,
        offerId = null,
        options = null,
        syncTime = null,
        syncLoading = false,
        callbackSync?: (status: boolean) => void,
        isCart = false) {

        // todo возможно следует оптимизировать, чтобы постоянно не юзать localStorage
        if (!this.appService.getUserToken()) {
            this.appService.closeModal();
            this.appService.closeProductModal();
            // очищаются значения
            this.appService.userPhone = '';
            this.appService.prevPhone = '';
            this.navigationService.goToPage('auth/phone');
            return;
        }

        offerId = offerId === null ? 0 : offerId;

        let activeOffer = null;
        let offerItem = null;
        if (product.offers && product.offers.length) {
            activeOffer = product.offers.find(item => item.id === offerId);

            // offerId = product.offerId;
            offerItem = {
                id: offerId,
                name: activeOffer?.name,
                size: activeOffer?.size,
            };
        }

        let productId = product.id;
        if (product.dishId) {
            productId = product.dishId;
        }

        let index = this.findProductIndex(productId, offerId);

        this.setCartDate();

        let step: OrderStep = OrderStep.Sync;

        if ((index === -1) && qty > 0) {
            const newProduct = new CartProduct();

            newProduct.id = productId ? productId : 0;
            newProduct.offer = offerItem;

            newProduct.name = product.name;
            newProduct.catName = product.catName ? product.catName : '';
            newProduct.isSauce = product.isSauce ? product.isSauce : false;
            newProduct.imageUrl = product.imageUrl;
            newProduct.defaultImg = product.defaultImg;

            newProduct.options = options;

            newProduct.price = activeOffer ? activeOffer.price : product.price;
            newProduct.oldPrice = activeOffer ? activeOffer.oldPrice : product.oldPrice;

            this.cart.products.push(newProduct);

            if (isCart) {
                step = OrderStep.Cart;
            }

            index = this.cart.products.length - 1;
        }

        if (this.cart?.products[index]) {

            let newQty = this.cart.products[index].qty + qty;
            // this.cart.products[index].description = product.description;
            // this.cart.products[index].wok = product.wok;

            if (newQty <= 0) {
                this.cart.products.splice(index, 1);
                newQty = 0;
                this.cart.total += -1;

                if (isCart) {
                    step = OrderStep.Cart;
                }
            } else {
                this.cart.products[index].qty = newQty;
            }

            if (newQty > 0) {

                let newTotal = this.cart.total + qty;
                if (newTotal <= 0) {
                    newTotal = 0;
                }

                this.cart.total = newTotal;
            }

            this.cart.totalPrice = this.cart.products.reduce((total, item) => total + item.getPrice, 0);
            this.cart.totalOldPrice = this.cart.products.reduce((total, item) => total + item.getOldPrice, 0);

            this.startSyncTimer(syncTime, syncLoading, callbackSync, step);
            // this.appService.cleanCdr();
            this.updateQuantity$.emit(null);
            return newQty;

        } else {
            this.startSyncTimer(syncTime, syncLoading, callbackSync, step);
            // this.appService.cleanCdr();
            this.updateQuantity$.emit(null);
            return 0;
        }
    }

    stopSyncTimer() {
        if (this.syncTimer) {
            clearTimeout(this.syncTimer);
            this.syncTimer = null;
        }
    }

    startSyncTimer(syncTime = null, syncLoading = false, callbackSync?, step: OrderStep = OrderStep.Sync) {

        this.stopSyncTimer();
        this.cancelHttpService.cancel(CancelHttpType.Cart);

        this.syncTimer = setTimeout(() => {
            this.sync(step, (status) => {
                if (callbackSync) {
                    callbackSync(status);
                }
            }, syncLoading);
        }, syncTime === null ? this.syncTimerDelay : syncTime);
    }

    findProductIndex(productId: number, offerId = 0) {

        // if (isWok) {
        //     return this.cart.products.findIndex(item => item.id === product.id || item.description === product.description);
        // }

        return this.cart?.products?.findIndex((item) => {
            if (item.offer && offerId && offerId !== 0) {
                return item.offer.id === offerId;
            } else {

                // console.log(item.id);
                // console.log(productId);
                // console.log('---');

                return item.id === productId;
            }
        });
    }

    getQty(productId, offerId = 0) {

        if (this.cart) {
            const index = this.findProductIndex(productId, offerId);

            if (this.cart?.products[index]) {
                return this.cart?.products[index].qty;
            }
        }

        return 0;
    }

    async sync(step: OrderStep, callback: (status: boolean) => void = null, loading = false) {

        this.stopSyncTimer();

        this.setCartDate();
        this.makeCartRequest(step, (answer) => {
            // если на 105 шаге не придёт order_id возвращается false
            if (!answer.status && step === OrderStep.InvoiceSaveOrderId) {
                callback(false);
                return;
            }

            if (answer.status && step === OrderStep.InvoiceSaveOrderId) {
                // если на 105 шаге корректно придёт order_id,
                // то он записывается в this.order_id и передаётся дальше
                if (answer.result.order_id) {
                    this.order_id = answer.result.order_id;
                }
            }

            if (answer.status) {

                if (answer.result && answer.result.cart && answer.result.cart?.id !== 0) {
                    this.cart = plainToClass(Cart, answer.result.cart);
                    if (this.cart.unAvailableProducts) {
                        this.unavailableProductsToShow = {...this.cart.unAvailableProducts};
                    }
                    this.checkDeposit();
                } else {
                    this.newCart();

                    if (answer.result && answer.result.cart) {
                        this.cart.options = answer.result.cart.options;
                    }
                }

                // this.appService.startUpdateCatalogTimer();

                if (answer.result.guest) {
                    this.appService.setGuestToken(answer.result.guest);
                } else {
                    this.appService.removeGuestToken();
                }

                this.appService.cleanCdr();

                if (step === OrderStep.Cart || step === OrderStep.Sync) {
                    this.isPromo = this.cart.promo?.type ? true : null;
                } else if (!this.cart.promo?.type && this.isPromo) {
                    this.isPromo = false;
                }


            } else {

                /*
                * @TODO обработчик ошибок
                * */
                this.appService.showMessage(answer.message);

            }

            if (callback) {
                callback(answer.status);
            }
        }, loading);

    }

    makeCartRequest(step: OrderStep, callback: (answer: ApiAnswer) => void = null, loading = false) {
        this.addDeviceInfo();

        const data: {step: number, cart: Cart, order_id?: string} = {
            step,
            cart: this.cart,
        };
        if (this.order_id) {
            data.order_id = this.order_id;
        }

        this.postRequest(data, (answer: ApiAnswer) => {
            callback(answer);
        }, 'cart.php', false, loading);
    }

    makeOrder(callback: (answer: ApiAnswer) => void = null) {

        this.setCartDate();
        this.setUTMData();
        this.makeCartRequest(OrderStep.OrderWithSaveOrderId, (answer) => {

            if (answer.result?.cart) {
                this.cart = plainToClass(Cart, answer.result.cart);
            }
            this.updateQuantity$.emit(null);
            callback(answer);
        });
    }

    cleanPromo() {
        this.cart.promo = new CartPromo();

        this.sync(OrderStep.Sync, null, true);
    }

    hasSouses() {
        if (this.cart?.products) {
            for (let i = 0; i < this.cart?.products.length; ++i) {
                if (this.cart?.products.hasOwnProperty(i)) {
                    if (this.cart?.products[i].hasSauces) {
                        return true;
                    }
                }
            }
        }
        return false;
    }

    fallibility(x: number, y) {
        return Math.trunc(HelperService.precisionRound(x * 100, 2)) * y / 100;
    }

    checkDeposit() {
        if (this.cart.promo?.type > 0 && this.cart.deposit.use) {
            this.appService.openModal(PopupMessageComponent, {
                text: 'Нельзя одновременно воспользоваться депозитом и промокодом. Для применения депозита отключите промокод.',
                isCloseButton: false
            });
            setTimeout(() => {
                this.cart.deposit.use = false;
            }, 100);
        }
    }

    private setUTMData() {

        const utmData = localStorage.getItem('com.ionicframework.hatimaki__deepLinkUTMData');
        const utmTimeExpired = localStorage.getItem('com.ionicframework.hatimaki__deepLinkUTMTimeExpired');
        if (utmTimeExpired && Date.now() < +utmTimeExpired && utmData && utmData !== 'undefined' && utmData !== 'null') {
            this.cart.data.utm = utmData;
        }
        localStorage.removeItem('com.ionicframework.hatimaki__deepLinkUTMData');
        localStorage.removeItem('com.ionicframework.hatimaki__deepLinkUTMTimeExpired');
    }

    getTotalPrice() {
        if (this.cart.deposit?.use && this.cart.deposit.totalDeposit) {
            return this.cart.totalFPrice + this.cart.deposit.totalDeposit;
        } else {
            return this.cart.totalFPrice;
        }
    }

    makeOnlinePaymentRequest(
        data: {
            link: string;
            data: {
                amount: number, // Сумма к оплате в копейках
                orderNumber: string,
                merchant: string,
                returnUrl: string,
                currencyCode: number
            }
        },
        callback: (answer: ApiAnswer) => void = null,
        loading = false) {

        this.postRequest(
            data,
            (answer: ApiAnswer) => {
                callback(answer);
            }, this.getUrl('payment/proxy', true), false, loading);

    }

    makeSbpPayment(data, callback: (answer: any) => void, loading = false) {
        this.isSpbPayment = true;
        this.postRequest(data, (response: ApiAnswer) => {
            if (callback) {
                callback(response);
            }
        }, 'api/v1/payment/alfa/getSbpQrCode', false);
    }

    checkSbpPayment(callback: (answer: any) => void, loading = false) {
        const postData = {
            qrId: this.cart.payment.qrId,
            mdOrder: this.cart.payment.mdOrder
        };
        this.postRequest(postData, (answer: ApiAnswer) => {
            if (callback) {
                callback(answer);
            }
        }, 'api/v1/payment/alfa/checkSbpQrCode', false);
    }

    async closeSbpPayment(callback: (answer: any) => void, loading = false) {
        this.isSpbPayment = false;
        const postData = {
            mdOrder: this.cart.payment.mdOrder
        };
        this.postRequest(postData, (answer: ApiAnswer) => {
            if (callback) {
                callback(answer);
            }
        }, 'api/v1/payment/alfa/cancelSbpQrCode', false);
    }

    getTotalPriceWithDeposit() {
        return this.getTotalPrice() - this.cart.deposit.totalDeposit < 0
            ? 0 : this.getTotalPrice() - this.cart.deposit.totalDeposit;
    }
}
