import {ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';

import {IonRouterOutlet, NavController, Platform} from '@ionic/angular';
import {CatalogService} from './models/catalog.service';
import {AppService, FirebaseTraces} from './services/app.service';
import {CartService} from './models/cart.service';
import {StatusBar} from '@ionic-native/status-bar/ngx';
import {UserService} from './models/user.service';
import {NavigationService} from './services/navigation.service';
import {CartDeliver, OrderStep} from './entity/cart.entity';
import {InfoService} from './models/info.service';
import {HistoryService} from './models/history.service';
import {SurveyService} from './models/survey.service';
import {Router} from '@angular/router';
import {Location} from '@angular/common';
import {Order} from './entity/order.entity';
import {DeepLinkService} from './services/deep-link.service';
import {DefaultAddress} from './entity/profile.entity';
import {DeliveryService} from './models/delivery.service';
import {BranchService} from './models/branch.service';
import {LocationService} from './services/location.service';
import {SuggestionsService} from './models/suggestions.service';
import {ModalService} from './services/modal.service';
import {PopupSelectCityComponent} from './components/popups/popup-select-city/popup-select-city';
import {Geolocation} from '@ionic-native/geolocation/ngx';
import {Geoposition} from '@ionic-native/geolocation';
import {PopupMessageComponent} from './components/popups/popup-message/popup-message.component';
import {AddressSearchAnswer} from './entity/addressSearchAPIAnswer.entity';

declare let cordova: any;

@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html',
    styleUrls: ['app.component.scss', 'fonts.scss']
})
export class AppComponent implements OnInit, OnDestroy {
    public selectedIndex = 0;

    @ViewChild(IonRouterOutlet, {static: true}) routerOutlet: IonRouterOutlet;

    private platform: Platform;
    public nav: NavController;
    public catalogService: CatalogService;
    public historyService: HistoryService;
    public appService: AppService;
    public cartService: CartService;
    private statusBar: StatusBar;
    public navigationService: NavigationService;
    private userService: UserService;
    private infoService: InfoService;
    public surveyService: SurveyService;
    public deepLinkService: DeepLinkService;
    public deliveryService: DeliveryService;
    public branchService: BranchService;
    locationService: LocationService;
    public router: Router;
    private location: Location;
    geolocation: Geolocation;
    cdr: ChangeDetectorRef;

    private loadingCounter = 2;
    private update: { needUpdate: boolean, version: any } = null;
    menuIsOpen = false;
    isAppActive = false; // Флаг для отслеживания состояния приложения
    private lastPausedTime: number | null = null; // Время последнего сворачивания
    private readonly pauseTimeLimit = 60 * 60 * 1000; // Лимит времени (60 минут) в миллисекундах

    constructor(
        platform: Platform,
        nav: NavController,
        catalogService: CatalogService,
        historyService: HistoryService,
        appService: AppService,
        cartService: CartService,
        statusBar: StatusBar,
        navigationService: NavigationService,
        userService: UserService,
        infoService: InfoService,
        surveyService: SurveyService,
        router: Router,
        location: Location,
        cdr: ChangeDetectorRef,
        deepLinkService: DeepLinkService,
        branchService: BranchService,
        deliveryService: DeliveryService,
        locationService: LocationService,
        geolocation: Geolocation,
        private suggestionsService: SuggestionsService,
        private  modalService: ModalService
    ) {
        this.platform = platform;
        this.nav = nav;
        this.catalogService = catalogService;
        this.historyService = historyService;
        this.appService = appService;
        this.cartService = cartService;
        this.statusBar = statusBar;
        this.navigationService = navigationService;
        this.userService = userService;
        this.infoService = infoService;
        this.surveyService = surveyService;
        this.router = router;
        this.location = location;
        this.cdr = cdr;
        this.deepLinkService = deepLinkService;
        this.deliveryService = deliveryService;
        this.branchService = branchService;
        this.locationService = locationService;
        this.geolocation = geolocation;

        this.initializeApp();
    }

    async initLocation(): Promise<void> {
        if (!this.appService.isCordova() && navigator.geolocation) {
            return new Promise((resolve, reject) => {
                navigator.geolocation.getCurrentPosition(
                    ({ coords }) => {
                        // Разрешение на доступ к геолокации получено
                        this.appService.coords = [coords.latitude, coords.longitude];
                        // console.log('this.coords', this.appService.coords);
                        resolve(); // Разрешаем продолжить выполнение
                    },
                    (error) => {
                        // Пользователь отклонил запрос на геолокацию или произошла ошибка
                        console.warn('Geolocation error:', error);
                        resolve(); // Все равно разрешаем продолжить выполнение (например, без геолокации)
                    },
                    {
                        enableHighAccuracy: true,
                        maximumAge: 0
                    }
                );
            });
        } else {
            return Promise.resolve(); // Если это не браузер, сразу разрешаем продолжить выполнение
        }
    }

    makeInit() {

        this.appService.getVersion(() => {

            this.loadingCounter = 2;

            // отключено, так как клиент не пользуется этой метрикой
            // this.appService.initAppMetrika();

            this.userService.makeLogin(() => {
                if (this.userService.defaultAddress) {
                    // если пользователь давно не делал заказы, то у него в defaultAddress.pickup
                    // может не быть параметра active. Поэтому принудительно проставляем active = true,
                    // а уже потом всё остальное
                    if (!this.userService.defaultAddress.pickup.active) {
                        this.userService.defaultAddress.pickup.active = true;
                        this.userService.saveDefaultAddress(this.userService.defaultAddress, () => {
                        }, {loadCatalog: false});
                    }

                    // если тип доставки самовывоз
                    if (this.userService.defaultAddress.type === 0) {
                        // запрос на получение всех филиалов, чтобы обновить данные по актуальными статусам
                        this.branchService.getData(res => {
                            // проверка наличия элемента с active=false и что его id совпадает с выбранным ранее филиалом
                            const inactiveBranch = this.branchService.data.find(
                                el => !el.active && el.id === this.userService.defaultAddress.pickup.id
                            );

                            // выбранный ранее филиал имеет статус active === false;
                            if (inactiveBranch) {
                                // по новой логике перенаправлять в этом случае на страницу
                                // location уже не требуется. Нужно дать пользователям в этом
                                // случае всё равно находиться на странице меню, смотреть цены
                                // и так далее. Всё равно в корзине дальше его не пропустит и
                                // вынудит выбрать корректный адрес или ресторан.
                                this.finalPreparations(true);
                            } else {
                                // находим нужный филиал, чтобы просто обновить время самовывоза и т. д.
                                const pickup = this.branchService.data.find(
                                    el => el.id === this.userService.defaultAddress.pickup.id
                                );

                                // если такой филиал существует
                                if (pickup) {
                                    // обновляем defaultAddress.pickup
                                    this.userService.defaultAddress.pickup = pickup;
                                    // сохраняем обновлённый defaultAddress
                                    this.userService.saveDefaultAddress(this.userService.defaultAddress, () => {
                                        // загрузка каталога не требуется
                                    }, {loadCatalog: false});
                                }

                                // если выбранныцй ранее филиал активен, то продолжается с обычной логикой
                                this.finalPreparations(true);
                            }
                        }, true);
                    } else {
                        // обновляем время доставки и минимальную цену
                        this.updateAddressData(this.userService.defaultAddress);

                        // если выбрана доставка по адресу, то продолжается с обычной логикой
                        this.finalPreparations(true);
                    }
                } else {
                    this.catalogService.getCatalog(status => {
                        if (status) {
                            this.finalPreparations(false);
                        }
                    }, '', true);
                }
            });

            this.checkAppVersion((result) => {
                this.update = result;
                this.checkForLoading();
            });
        });
    }

    updateAddressData(defaultAddress: DefaultAddress) {
        this.deliveryService.checkCoords(defaultAddress.courier.x, defaultAddress.courier.y, (status, result: any) => {
            if (status) {
                // заменяем defaultAddress courierPoint на новый
                this.userService.defaultAddress.courierPoint = result;
                // сохраняем обновлённый defaultAddress
                this.userService.saveDefaultAddress(this.userService.defaultAddress, () => {
                    // загрузка каталога не требуется
                }, {loadCatalog: false});
            } else {
                // по новой логике перенаправлять в этом случае на страницу
                // location уже не требуется. Нужно дать пользователям в этом
                // случае всё равно находиться на странице меню, смотреть цены
                // и так далее. Всё равно в корзине дальше его не пропустит и
                // вынудит выбрать корректный адрес или ресторан.
                // но статус в хедере нужно обновить
                if (!this.cartService.cart.delivery) {
                    this.cartService.cart.delivery = new CartDeliver();
                    this.cartService.cart.delivery.courierPoint = new AddressSearchAnswer();
                }
                setTimeout(() => {
                    this.cartService.cart.delivery.courierPoint.addressUnavailable = result.addressUnavailable;
                }, 20);
            }
        }, false);
    }

    getCountOrdersWithSurvey() {
        const userPhone = this.userService.user.login;

        this.surveyService.getSurveyCount(userPhone, status => {
            if (status) {
                // this.checkForLoading();
            }
        });
    }

    checkForLoading() {
        this.loadingCounter--;

        if (this.loadingCounter === 0) {

            document.documentElement.style.setProperty('--app-height', this.appService.getWindow().outerHeight + 'px');

            if (this.update.needUpdate) {

                if (this.update.version.critical.toString() === '2') {
                    this.appService.blockBack = true;
                }

                this.navigationService.goToPage('update', true, this.update.version);
            } else {
                this.navigationService.goToPage('menu', true);
            }
            this.appService.appReady = true;
            // this.appService.initLocation();
            this.appService.changeMenu();
        }
    }

    checkAppVersion(callback: (result: { needUpdate: boolean, version: any }) => void) {
        if (this.appService.isAndroid() || this.appService.isIos()) {
            this.infoService.loadVersion((data) => {
                if (parseFloat(data.critical) !== 0) {
                    const ver = this.appService.isIos() ? data.ios : data.android;
                    let versionCode = String(this.appService.appVersionNumber).replace(/\./g, '');

                    if (versionCode === 'browser') {
                        versionCode = '10000000000';
                        // versionCode = '1';
                    }

                    callback({
                        needUpdate: parseFloat(ver) > parseFloat(versionCode),
                        version: data
                    });
                } else {
                    callback({
                        needUpdate: false,
                        version: data
                    });
                }

            });
        } else {
            callback({
                needUpdate: false,
                version: null
            });
        }
    }

    initializeApp() {
        this.platform.ready().then(async () => {

            this.appService.showSplash();

            this.navigationService.routerOutlet = this.routerOutlet;

            this.appService.setStatusBar();
            // this.appService.fixStatusbarAfterReenter();

            this.platform.resume.subscribe(() => {
                this.appService.onForward = true;
                this.appService.updateStatusBar();
                this.appService.setStatusBar();
                const currentTime = Date.now();

                // Проверяем, прошло ли больше 60 минут с момента сворачивания
                if (this.lastPausedTime && currentTime - this.lastPausedTime > this.pauseTimeLimit) {
                    this.restartApp(); // Перезапускаем приложение
                }

                this.lastPausedTime = null; // Сбрасываем время сворачивания
            });

            this.platform.pause.subscribe(() => {
                this.isAppActive = false; // Приложение свернуто
                this.appService.onForward = false;
                this.lastPausedTime = Date.now(); // Сохраняем время сворачивания
            });

            if (!this.appService.isDevice()) {
                this.appService.deviceId = 'browsertoken';
            }

            this.appService.windowHeight = window.outerHeight;


            // console.log('afterUpdate');
            // console.log(this.appService.isCordova());

            if (this.appService.isCordova()) {
                try {
                    // Сначала подписываемся на пуш-уведомления и ждем их завершения
                    await this.appService.subscribePush(() => {
                            // Продолжаем инициализацию
                            this.makeInit();
                        }, (data) => {
                            // console.log('объект пуша', data);
                            // Обработка данных из пуш-уведомлений
                            if (data.isUpdateStopList) {
                                this.catalogService.getCatalogStopList(() => {
                                    this.cdr.detectChanges();
                                }, this.userService.token);
                            }

                            if (data.updateOrderHistory === '1') {
                                this.historyService.getData();
                            }

                            if (data.deeplink) {
                                try {
                                    if (data.show_notification !== 'false') {
                                        this.deepLinkService.openInnerLink(data.deeplink);
                                    }
                                } catch (e) {
                                    console.warn('error', e);
                                }
                            }

                            if (data.needUpdateMap === '1') {
                                // Устанавливаем флаг необходимости обновления карты
                                this.locationService.setUpdateMapFlag(true);
                                if (data.show === '1') {
                                    // Проверяем, открыто ли приложение
                                    // если приложение в момент прихода пуша - показывается модалка,
                                    // так как пуши не отображаются если приложение открыто
                                    if (this.isAppActive) {
                                        this.appService.openModal(PopupMessageComponent, {
                                            title: data.title,
                                            text: data.text,
                                            isCloseButton: true
                                        });
                                    }
                                }
                            }
                        }
                    );
                } catch (error) {
                    console.error('Error during initialization', error);
                    // Продолжаем инициализацию даже в случае ошибки подписки
                    this.makeInit();
                }
            } else {
                // Если это браузер, ждем решения пользователя
                await this.initLocation();

                // После этого продолжаем инициализацию приложения
                this.makeInit();
            }
            this.platform.resume.subscribe(() => {
                this.isAppActive = true; // Приложение активно
                if (this.userService.isLogin()) {
                    this.historyService.setSyncTimer(false);
                }
                // запрос на гепозиция после сворачивания приложение должен
                // происходить только если ранее пользователь дал разрешение
                if (this.locationService.choice === 'GRANTED') {
                    this.locationService.watchPosition();
                }
            });

            this.isAppActive = true; // Устанавливаем по умолчанию как активное при старте
        });

        this.platform.backButton.subscribeWithPriority(1, () => {

            if (this.appService.productModalIsOpened) {
                this.appService.closeProductModal();
            } else if (
                !this.appService.productModalIsOpened &&
                !this.appService.isOpenModal &&
                this.location.isCurrentPathEqualTo('/menu')
            ) {
                (navigator as any).app.exitApp();
            } else {
                this.navigationService.back();
            }
        });
    }

    /**
     * Метод для перезапуска приложения
     */
    restartApp() {
        // console.log('Приложение было свернуто более 60 минут. Перезапуск...');
        document.location.reload(); // Полный перезапуск приложения
    }

    ngOnInit() {
        this.appService.firebasex.setPerformanceCollectionEnabled(true).then(() => {
            this.appService.firebasex.startTrace(FirebaseTraces.menuReady);
        });
    }

    openCitySelection() {
        this.navigationService.goToPage('city-selection');
    }

    ngOnDestroy() {
    }

    openChat() {
        if (this.userService.isLogin()) {
            this.appService.getWindow().Verbox('openSupport');
        } else {
            this.appService.userPhone = '';
            this.navigationService.goToPage('auth/phone');
        }
    }

    finalPreparations(loadCatalog) {
        if (loadCatalog) {
            this.catalogService.getCatalog(() => {
                this.checkForLoading();
                this.catalogService.showBranchPopup();
            }, this.userService.token);
        } else {
            this.catalogService.showBranchPopup();
            this.checkForLoading();
        }

        // подписываемся на событие о необходимости обновить каталог
        this.appService.updateCatalog.emitter.subscribe(() => {
            this.catalogService.getCatalog(() => {
                this.appService.changeMenu();
            }, this.userService.token, true);
        });

        if (this.userService.isLogin()) {
            this.getCountOrdersWithSurvey();
            this.appService.addUserToPush(this.userService.user.userId);
            this.cartService.sync(OrderStep.Start);
            this.historyService.getData(1, true, () => {}, false, true);
        } else {

            if (this.appService.getGuestToken()) {
                this.cartService.sync(OrderStep.Start);
            } else {
                this.cartService.newCart();
            }

        }
    }

    getRoutesForMenu() {
        const survey = this.catalogService.settings?.menu?.survey;
        return this.router.config.filter(item => {
            if (item.path === 'survey' && !survey) {
                return false;
            }
            if (!item.path.includes('auth') && this.userService.isLogin()) {
                return item.data?.menu;
            } else if (item.path !== 'profile/main' && !this.userService.isLogin()) {
                return item.data?.menu;
            }
        });
    }

    toggleMenuIsOpen(value: boolean) {
        this.menuIsOpen = value;
    }

    openVacancies() {
        this.appService.openLink(this.catalogService.vacancies || 'https://www.hatimaki.ru/vakansii/');
    }

    goToPage(path: string) {
        if (path === 'auth/phone') {
            this.appService.userPhone = '';
        }
        this.navigationService.goToPage(path);
    }
}
