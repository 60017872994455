import {Entity} from '../core/main.entity';
import {Address} from './address.entity';
import {Branch} from './restaurant.entity';
import {Comment} from './comment.entity';
import {AddressSearchAnswer} from './addressSearchAPIAnswer.entity';
import {WokItem} from './wokItem.entity';
import {Type} from 'class-transformer';
import {Product} from './product.entity';
import {CartDeposit} from './cart-deposit.entity';

export class Cart implements Entity {
    id = 0;

    @Type(() => CartProduct)
    products: CartProduct[] = [];
    total = 0;
    totalFPrice = 0;
    totalPrice = 0;
    totalOldPrice = 0;

    // @Type(() => CartFreeProduct)
    freeProducts: CartFreeProduct[] = [];
    date: number;

    // @Type(() => CartPromo)
    promo = new CartPromo();

    @Type(() => CartDeliver)
    delivery = new CartDeliver();

    // @Type(() => CartData)
    data = new CartData();

    // @Type(() => CartPayment)
    payment = new CartPayment();

    // @Type(() => CartOptions)
    options = new CartOptions();

    recommended: Product[] = [];
    unAvailableProducts: null | CartUnavailableProducts = null;

    deposit: CartDeposit = new CartDeposit();
}

export class TimePicker implements Entity {
    deliveryTitle: string;
    deliveryText: string;
    pickupTitle: string;
    pickupText: string;
    days: number;
    pickupButton?: string;
    deliveryButton?: string;
}


export class CartData implements Entity {
    name: string;
    phone: string;
    email: string;
    persons: number;
    cutlery: CartCutlery = new CartCutlery();
    callback: string;
    callbackRequired: boolean;

    // @Type(() => Comment)
    comment: Comment = new Comment();
    device: string;
    notifications: string[];
    version: string;
    utm: any;
    cheque: number;
}

export class CartCutlery implements Entity {
    forks: number;
    spoons: number;
    sticks: number;
    forksMax: number;
    spoonsMax: number;
    sticksMax: number;
}

export class CartDeliver implements Entity {
    type: DeliveryType = DeliveryType.Courier;
    courier: Address = new Address();
    courierPoint: AddressSearchAnswer;
    courierBranch: Branch = new Branch();
    courierTime = '';
    courierDate = '';
    pickup: Branch = new Branch();
    pickupTime = '';
    pickupDate = '';

    public getDate(){
        // эта функция возвращает либо дату в формате день месяц, либо 'сегодня' или 'завтра'
        const date = this.type === DeliveryType.Courier ? this.courierDate : this.pickupDate;
        let value = '';
        if (date) {
            const today = new Date().toLocaleString('ru',
                {
                    day: 'numeric',
                    month: 'numeric',
                    year: 'numeric'
                });
            const tomorrow = new Date(new Date().getTime() + (24 * 60 * 60 * 1000)).toLocaleString('ru',
                {
                    day: 'numeric',
                    month: 'numeric',
                    year: 'numeric'
                });
            if (today === date) {
                value = 'Сегодня';
            } else if (tomorrow === date) {
                value = 'Завтра';
            } else {
                value = new Date(date?.split('.').reverse().join('/')).toLocaleString('ru',
                    {
                        day: 'numeric',
                        month: 'long',
                    }).split('.')[0];
            }
        }
        return value;
    }

    public get getMerchantLogin() {
        if (this.type === DeliveryType.Courier) {
            return this.courierBranch.accountNumber;
        }

        if (this.type === DeliveryType.Pickup) {
            return this.pickup.accountNumber;
        }
    }

    public get getClientId() {
        if (this.type === DeliveryType.Courier) {
            return this.courierBranch.accountNumber;
        }

        if (this.type === DeliveryType.Pickup) {
            return this.pickup.accountNumber;
        }
    }
}

export class CartPromo implements Entity {
    code: string = null;
    type: PromoType = null;
    description: string = null;
    title: string = null;
}

export class CartPayment implements Entity {
    type: PaymentType = PaymentType.SBPPayment;
    exchange = '0';
    bankOrderId: string = null;
    qrId: string = null;
    mdOrder: string = null;
}

export class CartOptions implements Entity {
    courierTimeStart = '11:00';
    courierTimeEnd = '23:00';
    courierTimeDelay = '60';
    pickupTimeStart = '11:00';
    pickupTimeEnd = '23:00';
    pickupTimeDelay = '30';
    availablePayment: PaymentType[] = [];
    availableDelivery: DeliveryType[] = []; // не используем
    timePicker: TimePicker;
}

export class CartProduct implements Entity {
    id: number;
    name: string;
    catName: string;
    imageUrl?: string;
    qty = 0;

    @Type(() => CartOfferItem)
    offer = null;

    // @Type(() => WokItem)
    options: WokItem[] = [];
    fprice = 0;
    price = 0;
    oldPrice = 0;
    hasSauces: boolean;
    isSauce: boolean;
    defaultImg: string;

    public get optionsDesc(): string {
        const desc = this.options.map(item => {

            if (item?.checked) {
                if (item.id.toString() === '872' || item.single) {
                    return item.name;
                } else {
                    return `${item.name} ${item.quantity} шт.`;
                }
            } else {
                return null;
            }

        });

        return desc.filter((el) => {
            return el != null;
        }).join('<br/>');
    }

    public get getPrice() {
        return this.price * this.qty;
    }

    public get getOldPrice() {
        return this.oldPrice * this.qty;
    }
}

export class CartUnavailableProducts implements Entity {
    text: string;
}

export class CartProductOptions implements Entity {
    id: number;
    offer: number;
    title: string;
    qty: number;
    price: number;
    oldPrice: number;
}

export class CartOfferItem implements Entity {
    id: number;
    name: string;
    size: string;
}

export class ApiAnswer implements Entity {
    status: boolean;
    message: string;
    result: any;
}

export class CartFreeProduct implements Entity {
    id: number;
    title: string;
    description: string;
    img: string;
    qty: number;
}

export enum DeliveryType {
    Pickup = 0,
    Courier = 1
}

export enum PromoType {
    Invalid = 0,
    Product = 1,
    Price = 2,
    Delivery = 3,
}

export enum OrderStep {
    Cart = 0,
    Delivery = 1,
    Information = 2,
    Payment = 4,
    Invoice = 5,
    Confirmation = 6,
    OnlinePayment = 7,
    Start = 8,
    Order = 9,
    Sync = 10,
    CheckUnavailable = 101,
    RemoveUnavailable = 102,
    InvoiceSaveOrderId = 105,
    OrderWithSaveOrderId = 109
}

export enum PaymentType {
    Cash = 0,
    Cashless = 1,
    GooglePayment = 2,
    ApplePayment = 3,
    OnlinePayment = 4,
    SBPPayment = 5,
}

